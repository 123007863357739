<template>
  <div v-if="loading" class="loader">
    <loader></loader>
  </div>
  <v-card v-else :dark="$dark.get()" fluid height="92vh">
    <v-card-title>
      <div class="header">Зоны доставки</div>

      <v-chip-group
        id="chip_role"
        v-model="depId"
        column
        mandatory
        class="pl-2"
        @change="changeDep()"
      >
        <v-chip
          v-for="item in department"
          :key="item.id"
          class="text-center"
          color="primary"
          filter
          style="height: 36px; color: white"
          :value="item.id"
        >
          <span style="color: white; font-size: 14px; font-weight: 500">{{
            item.name
          }}</span>
        </v-chip>
      </v-chip-group>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" style="padding-top: 2.5px">
          <yandex-map
            :zoom="map_d.zoom"
            :settings="map_settings"
            :coords="map_d.coords"
            map-type="map"
            @boundschange="map_boundschange"
            @map-was-initialized="initMap"
          >
          </yandex-map>
        </v-col>
        <v-col cols="12">
          <v-simple-table v-if="all_zone.length > 0" class="table">
            <thead>
              <tr>
                <th class="table_depart_head">ID доставки</th>
                <th class="table_depart_head">Наименование района</th>
                <th class="table_depart_head">Стоимость доставки</th>
                <th class="table_depart_head">Посмотреть</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in all_zone" :key="item.zoneId">
                <td>
                  {{ item.zoneId }}
                </td>
                <td>
                  {{ item.name }}
                </td>
                <td>
                  {{ item.price }}
                </td>
                <td>
                  <v-btn color="primary" :to="`/area/${item.zoneId}-${depId}`"
                    >Посмотреть
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <p v-else>У даннго филиала еще нет зон доставки</p>
        </v-col>
      </v-row>
    </v-card-text>
    <div style="position: fixed; left: 93%; bottom: 5%">
      <v-btn
        id="add_area_button"
        fab
        color="success lighten-1"
        small
        class="elevation-4"
        to="/area/new"
        ><v-icon>mdi-plus</v-icon></v-btn
      >
    </div>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "../components/Loader";
export default {
  components: { Loader },
  data() {
    return {
      myMap: {},
      all_zone: [],
      polygon_list: [],
      active_edit_polygon_id: 0,
      edit_polygon_data: [],
      edit_polygon: {},
      stateMonitor: {},
      chip: null,
      map_d: {
        zoom: 12,
        coords: [49.783936, 73.145232],
      },
      loading: false,
      map_settings: {
        apiKey: "",
        lang: "ru_RU",
        coordorder: "latlong",
        version: "2.1",
      },
      btn: {
        department: {},
        edit: {},
        save: {},
        remove: {},
        close: {},
      },
      dialog: false,
      department: [],
      depId: null,
      depName: "",
    };
  },
  computed: {
    ...mapGetters({
      DEPARTMENT: "Department/STATE",
      ZONE: "Zone/STATE",
      USER: "User/STATE",
    }),
  },
  watch: {
    all_zone: {
      handler() {
        this.add_polygon_list();
      },
      deep: true,
    },
  },
  async created() {
    this.loading = true;
    await this.getAllDepartment(this.$root.city);
    this.department = this.DEPARTMENT.cityDepartment;
    this.all_zone = await this.getAllZoneList(this.department[0].id);
    console.log(this.all_zone);
    this.depId = this.department[0].id;
    this.loading = false;
  },
  methods: {
    ...mapActions({
      getAllDepartment: "Department/GET_DEPARTMENT",
      getAllZoneList: "Zone/GET_ALL_ZONE_LIST",
    }),
    map_boundschange(e) {
      if (parseInt(e._cache.newZoom) > 0) {
        this.map_d.zoom = e._cache.newZoom;
      }
      if (typeof e._cache.newCenter.isArray === "undefined") {
        this.map_d.coords = e._cache.newCenter;
      }
    },
    initMap(map) {
      this.myMap = map;
      this.add_polygon_list();
    },
    create_polygon() {
      // Создаем полигон для карты
      this.$set(this, "edit_polygon_data", {
        id: 0,
        coords: [],
        name: "",
        price: 0,
      });
      this.myMap.geoObjects.add(this.edit_polygon);
    },
    add_polygon_list() {
      // Создает из массива список полигонов на карте
      // Сначала удаляем все полигоны
      for (var i = 0; i < this.polygon_list.length; i++) {
        this.myMap.geoObjects.remove(this.polygon_list[i]);
      }

      if (typeof this.myMap["geoObjects"] == "undefined") return false;
      for (var i = 0; i < this.all_zone.length; i++) {
        var coords = this.all_zone[i].coords;
        if (typeof coords == "string")
          coords = JSON.parse(this.all_zone[i].coords);

        this.polygon_list[i] = new ymaps.Polygon(
          [coords],
          {
            hintContent: this.all_zone[i].name,
          },
          {
            fillColor: "#00adff",
            interactivityModel: "default#transparent",
            strokeWidth: 1,
            opacity: 0.5,
          }
        );
        this.myMap.geoObjects.add(this.polygon_list[i]);
        this.polygon_list[i].id = this.all_zone[i].zoneId;

        this.polygon_list[i].events.add("click", (e) => {
          this.active_edit_polygon_id = e._cache.target.zoneId;
        });
      }
    },
    async changeDep() {
      this.all_zone = await this.getAllZoneList(this.depId);
    },
  },
};
</script>
<style lang="scss">
.ymap-container {
  @media screen and (max-width: 3000px) {
    min-height: 32vmax;
    height: 75%;
  }
  @media screen and (max-width: 1920px) {
    min-height: 530px;
    height: 80%;
  }
  @media screen and (max-width: 1440px) {
    min-height: 500px;
    height: 66%;
  }
  @media screen and (max-width: 1366px) {
    min-height: 380px;
    height: 45%;
  }
}
.table {
  @media screen and (max-width: 3000px) {
    min-height: 32vmax;
    height: 75%;
  }
  @media screen and (max-width: 1920px) {
    height: 220px;
    overflow-y: auto;
  }
  @media screen and (max-width: 1440px) {
    height: 200px;
    overflow-y: auto;
  }
  @media screen and (max-width: 1366px) {
    height: 180px;
    overflow-y: auto;
  }
}
.ymap_btn {
  background-color: #fff;
  border-color: transparent;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.15),
    0 2px 5px -3px rgba(0, 0, 0, 0.15);
  box-sizing: border-box !important;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  background-clip: border-box;
  color: #000;
  vertical-align: middle;
  text-decoration: none;
  font-family: Arial, Helvetica, sans-serif;
  cursor: pointer;
  -webkit-transition: background-color 0.15s ease-out,
    border-color 0.15s ease-out, opacity 0.15s ease-out;
  transition: background-color 0.15s ease-out, border-color 0.15s ease-out,
    opacity 0.15s ease-out;
  display: inline-block;
  text-align: left;
  height: 28px;
  -ms-user-select: none; /* Internet Explorer */
  -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none; /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
  padding: {
    top: 4px;
    left: 10px;
    right: 10px;
  }

  &--blue {
    color: #fff;
    background-color: rgb(85, 201, 255);
  }
  &--red {
    color: #fff;
    background-color: #f55;
  }
  &--yellow {
    color: #fff;
    background-color: #f4c712;
  }
  &--green {
    color: #fff;
    background-color: #00c469;
  }
}
.loader {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh;
}
</style>
